.faqs {
  margin: 0 auto 60px;
}
.faqs .faq-row-wrapper {
  position: relative;
}
.faqs .faq-row-wrapper .faq-body {
  border-radius: 0px;
}
.faqs .faq-row-wrapper .faq-title {
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  margin: 0px 0px 40px;
  border: none;
}
.faqs .faq-row-wrapper .faq-title h2 {
  font-size: 42px !important;
}
.faqs .faq-row-wrapper .faq-body .faq-row {
  margin-bottom: 5px;
  padding: 0px;
  border: none;
}
.faqs .faq-row-wrapper .faq-body .faq-row .row-title {
  padding: 10px 40px 10px 0px;
  font-family: "defiworld-Bold";
  letter-spacing: 1px;
  margin-top: 15px;
}
.faqs .faq-row-wrapper .faq-body .faq-row .icon-wrapper {
  top: 10px !important;
  right: 0px !important;
  background-color: #214e7a;
}
.faqs .faq-row-wrapper .faq-body .faq-row .row-content-text {
  color: #ffffff !important;
  font-size: 14px !important;
  opacity: 0.9;
  padding-right: 40px !important;
}
.faqs .faq-row-wrapper .faq-body .faq-row .row-content-text a {
  color: #64bbff;
  font-size: 16px;
}

.faQ {
  color: #50dbff !important;
}

.ctr {
  margin: 0 auto;
  max-width: 1320px;
}

@media only screen and (max-width: 768px) {
  .faqs .faq-row-wrapper {
    padding: 0px;
  }
  .ctr {
    padding: 20px;
  }
  .faqs .faq-row-wrapper .faq-body {
    padding: 0px;
  }
  .faqs .faq-row-wrapper .faq-body .faq-row .row-title {
    margin-top: 0px;
  }
  .faqs .faq-row-wrapper::before,
  .faqs .faq-row-wrapper::after {
    display: none;
  }
  .faqs .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY {
    font-size: 16px;
  }
  .faqs
    .styles_faq-row-wrapper__3vA1D
    .styles_row-body__1NvUo
    .styles_faq-row__2YF3c
    .styles_row-title__1YiiY
    .styles_row-title-text__1MuhU {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 1400px) {
  .ctr {
    padding: 20px;
  }
}
