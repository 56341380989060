@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nova+Square&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mako&family=Nova+Square&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mako&family=Nova+Square&family=Squada+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aldrich&display=swap");
footer {
  text-align: center !important;
  padding: 30px 15px !important;
  position: relative !important;
}
footer::before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  background-color: #000;
  width: 100%;
  height: 146px;
  background-image: url(../../assets//images/header-frame.svg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  -webkit-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  transform: scaleY(-1);
}
footer .copyright {
  text-align: center;
}
footer .copyright p {
  padding: 0px 0px !important;
  margin-top: 8px;
  font-size: 14px;
}
footer .socialIcons ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.socialIcons ul li svg {
  fill: #50dbff;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  padding: 10px;
}

.socialIcons ul li {
  margin: 0px 8px;
}

.socialIcons ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}

.socialIcons ul li a {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #214e7a;
}

@media only screen and (max-width: 1585px) {
  footer ul li a {
    width: 35px;
    height: 35px;
  }
  footer ul li svg {
    padding: 7px;
  }
}
@media only screen and (max-width: 575px) {
  footer ul li {
    margin: 0px 3px;
  }
  footer ul li a {
    width: 35px;
    height: 35px;
  }
  footer ul li svg {
    padding: 7px;
  }
  footer .socialIcons ul {
    padding: 0;
    margin: 0px;
  }
  footer .socialIcons ul li {
    margin: 0 4px 8px;
  }
  footer .socialIcons ul li a {
    height: 32px;
    width: 32px;
  }
  footer .socialIcons ul li svg {
    padding: 7px;
  }
  footer .socialIcons {
    margin-bottom: 12px;
  }
}
