header {
  position: relative;
  -webkit-filter: drop-shadow(0px -60px 60px #000b2b);
          filter: drop-shadow(0px -60px 60px #000b2b);
  top: 0px;
  z-index: 4;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header.openmenu {
  position: sticky;
}
header .container {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}
header .wnbtn {
  border: none;
  color: #fff;
  background-color: #bc4242;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 4px;
}
header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 170px;
  background-image: url(../../assets/images/header-frame.svg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.logo img {
  width: 100%;
  max-width: 60px;
  padding: 15px 15px 15px 0px;
}

.iekbcc0.ju367v70.ju367v7l.ju367v85.ju367v6e.ju367v9x.ju367vcf.ju367vn.ju367vt.ju367vw.ju367vfo.ju367v11.ju367v1c.ju367v8o {
  width: 135px;
}

.logoDesktop {
  top: 80px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
.logoDesktop img {
  width: 100%;
  max-width: 120px;
  padding: 5px;
  -webkit-transform: scale(1.5);
      -ms-transform: scale(1.5);
          transform: scale(1.5);
}
.logoDesktop h2 {
  font-family: "defiworld-Bold1";
  font-size: 42px;
  color: #3df9ff;
}
.logoDesktop ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "defiworld-Bold1";
  margin: 0px;
  padding: 0px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-shadow: 2px 2px 50px #50DBFF;
}
.logoDesktop ul li {
  list-style: none;
  letter-spacing: 6px;
  font-size: 32px;
  -webkit-animation: ani 1.2s linear infinite;
          animation: ani 1.2s linear infinite;
}
.logoDesktop ul li:nth-child(1) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.logoDesktop ul li:nth-child(2) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.logoDesktop ul li:nth-child(3) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.logoDesktop ul li:nth-child(4) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.logoDesktop ul li:nth-child(5) {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}
.logoDesktop ul li:nth-child(6) {
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}
.logoDesktop ul li:nth-child(7) {
  -webkit-animation-delay: 1.12s;
          animation-delay: 1.12s;
}
.logoDesktop ul li:nth-child(8) {
  -webkit-animation-delay: 1.16s;
          animation-delay: 1.16s;
}
.logoDesktop ul li:nth-child(9) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
@-webkit-keyframes ani {
  0% {
    text-shadow: 2px 2px 10px #50dbff;
  }
  90% {
    text-shadow: 2px 2px 20px #50dbff;
  }
  100% {
    text-shadow: 0 0 7px #3df9ff, 0 0 20px #3df9ff;
  }
}
@keyframes ani {
  0% {
    text-shadow: 2px 2px 10px #50dbff;
  }
  90% {
    text-shadow: 2px 2px 20px #50dbff;
  }
  100% {
    text-shadow: 0 0 7px #3df9ff, 0 0 20px #3df9ff;
  }
}

.logoDesktop2 {
  top: 120px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
.logoDesktop2 img {
  width: 100%;
  max-width: 120px;
  padding: 5px;
  -webkit-transform: scale(1.5);
      -ms-transform: scale(1.5);
          transform: scale(1.5);
}
.logoDesktop2 h2 {
  font-family: "defiworld-Bold1";
  font-size: 42px;
  color: #3df9ff;
}
.logoDesktop2 ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "defiworld-Bold1";
  margin: 0px;
  padding: 0px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
}
.logoDesktop2 ul li {
  list-style: none;
  letter-spacing: 6px;
  font-size: 32px;
  -webkit-animation: ani 1.2s linear infinite;
          animation: ani 1.2s linear infinite;
}
.logoDesktop2 ul li:nth-child(1) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.logoDesktop2 ul li:nth-child(2) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.logoDesktop2 ul li:nth-child(3) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.logoDesktop2 ul li:nth-child(4) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.logoDesktop2 ul li:nth-child(5) {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}
.logoDesktop2 ul li:nth-child(6) {
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}
.logoDesktop2 ul li:nth-child(7) {
  -webkit-animation-delay: 1.12s;
          animation-delay: 1.12s;
}
.logoDesktop2 ul li:nth-child(8) {
  -webkit-animation-delay: 1.16s;
          animation-delay: 1.16s;
}
.logoDesktop2 ul li:nth-child(9) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
@keyframes ani {
  0% {
    text-shadow: 2px 2px 10px #50dbff;
  }
  90% {
    text-shadow: 2px 2px 20px #50dbff;
  }
  100% {
    text-shadow: 0 0 7px #3df9ff, 0 0 20px #3df9ff;
  }
}

.buttonGroup.btn1 {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-image: url(../../assets//images/btnbg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 15px;
  font-weight: 800;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  letter-spacing: 0.8px;
  min-height: 91px;
  min-width: 180px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  background-position: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
}
.buttonGroup.btn1:hover {
  color: #00FFFF !important;
}

.menu {
  position: relative;
  top: 0px;
}
.menu li {
  display: inline-block;
  margin: 0px 12px;
}
.menu a {
  display: block;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 16px;
  letter-spacing: 1.5px;
  font-weight: 400;
  cursor: pointer;
}

.balancebox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.balancebox li {
  display: inline-block;
}
.balancebox li:nth-last-child(1) {
  margin-left: 10px;
}
.balancebox li span {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  border: 1px solid #50dbff;
  font-weight: 600;
  letter-spacing: 2px;
  position: relative;
  min-width: auto;
  margin: 0px;
}
.balancebox li span::before {
  width: 100px !important;
}
.balancebox li span::after {
  width: 100px !important;
}
.balancebox li strong {
  margin-left: 8px;
}

.rightboxmenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  position: relative;
  bottom: 0px;
}

.hamburger {
  cursor: pointer;
  width: 35px !important;
  height: 26px;
  position: relative;
  margin-left: 15px;
  z-index: 1;
}
.hamburger span {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  background-color: #02ffff;
  display: inline-block;
  content: "";
  -webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  top: 10px;
  left: 0px;
  width: 100%;
}
.hamburger span:before {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 100%;
  background-color: #50dbff;
  position: absolute;
  display: inline-block;
  content: "";
  top: -10px;
  left: 0px;
  -webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 100%;
  background-color: #50dbff;
  position: absolute;
  display: inline-block;
  content: "";
  bottom: -10px;
  left: 0px;
  -webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger.is-active span {
  background-color: transparent;
}
.hamburger.is-active span:before {
  top: 0;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.hamburger.is-active span:after {
  top: 0;
  -webkit-transform: translateY(-10px) rotate(-45deg);
      -ms-transform: translateY(-10px) rotate(-45deg);
          transform: translateY(-10px) rotate(-45deg);
  top: 10px;
}

.menubar {
  position: fixed;
  top: 0;
  left: -360px;
  width: 0px;
  height: 100%;
  z-index: 3;
  background-color: #041516;
  display: block !important;
  -webkit-box-shadow: -20px 0px 30px 0px #000000;
          box-shadow: -20px 0px 30px 0px #000000;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding-top: 140px;
  padding-left: 20px;
}
.menubar .menu {
  display: block;
  padding-top: 30px !important;
}
.menubar .menu li {
  display: block;
  margin-bottom: 10px;
}
.menubar .menu li a {
  display: block;
  text-align: left;
  padding: 10px;
  font-size: 20px;
}

.mobileMenu {
  left: 0;
  width: 100%;
}

.socialIcons ul li svg {
  fill: #50dbff;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 10px;
}

.position-relative {
  position: relative !important;
}

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 66.66666667%;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: calc(-1 * var(--cui-gutter-y));
}

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 50%;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.approvebtn1 {
  padding: 4px;
  height: 40px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(35, 144, 155, 0.71)), color-stop(70.45%, rgb(53, 131, 177))) !important;
  background-image: -o-linear-gradient(rgba(35, 144, 155, 0.71) 0%, rgb(53, 131, 177) 70.45%) !important;
  background-image: linear-gradient(rgba(35, 144, 155, 0.71) 0%, rgb(53, 131, 177) 70.45%) !important;
  font-family: "defiworld-Regular";
  border-radius: 5px;
  border: 1px solid hsl(199.38, 71.03%, 56.51%);
  font-weight: 500;
  cursor: pointer;
  margin: 0 auto;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  margin-top: 15px;
  color: #ffffff;
  font-size: 22px;
}
.approvebtn1.mt-25 {
  margin-top: 25px;
}
.approvebtn1 img {
  height: 14px;
  width: auto;
  position: relative;
  left: 8px;
  -webkit-filter: invert(1);
          filter: invert(1);
}
.approvebtn1 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px 0px;
}
.approvebtn1 span::before, .approvebtn1 span::after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: hsl(199.38, 71.03%, 56.51%);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.approvebtn1 span::before {
  width: 2px;
  height: 0%;
}
.approvebtn1 span::after {
  width: 0%;
  height: 2px;
}
.approvebtn1 span:hover::before {
  height: 100%;
}
.approvebtn1 span:hover::after {
  width: 100%;
}
.approvebtn1::before, .approvebtn1::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: hsl(199.38, 71.03%, 56.51%);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.approvebtn1::before {
  height: 0%;
  width: 2px;
}
.approvebtn1::after {
  width: 0%;
  height: 2px;
}
.approvebtn1:hover {
  background: transparent;
  color: hsl(199.38, 71.03%, 56.51%);
}
.approvebtn1:hover::before {
  height: 100%;
}
.approvebtn1:hover::after {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .d-md-none {
    display: none !important;
  }
}
@media only screen and (max-width: 575px) {
  .hamburger {
    width: 25px !important;
  }
}
@media only screen and (max-width: 1840px) {
  header .container {
    max-width: 950px;
  }
  .menu li {
    margin: 0px 10px;
  }
}
@media only screen and (max-width: 1440px) {
  .menu li {
    margin: 0px 10px;
  }
  .menu a {
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 500;
  }
  header .container {
    max-width: 950px;
  }
  header .clipHeader {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 15px) calc(100% - 20px), calc(100% - 170px) calc(100% - 20px), calc(100% - 190px) calc(100% - 40px), 180px calc(100% - 40px), 150px 100%, 0 100%, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 15px) calc(100% - 20px), calc(100% - 170px) calc(100% - 20px), calc(100% - 190px) calc(100% - 40px), 180px calc(100% - 40px), 150px 100%, 0 100%, 0 0);
  }
  header .clipHeader::before {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 15px) calc(100% - 20px), calc(100% - 170px) calc(100% - 20px), calc(100% - 190px) calc(100% - 40px), 180px calc(100% - 40px), 150px 100%, 10px 100%, 2px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 15px) calc(100% - 20px), calc(100% - 170px) calc(100% - 20px), calc(100% - 190px) calc(100% - 40px), 180px calc(100% - 40px), 150px 100%, 10px 100%, 2px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
  }
  header .before {
    width: 300px;
  }
}
@media only screen and (max-width: 992px) {
  .balancebox li span {
    padding: 10px 15px;
    letter-spacing: 1px;
    margin: 0px;
  }
}
@media only screen and (max-width: 767px) {
  header .clipHeader {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 0px) calc(100% - 40px), calc(100% - 0px) calc(100% - 0px), calc(100% - 0px) calc(100% - 40px), 180px calc(100% - 40px), 140px 100%, 0 100%, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 0px) calc(100% - 40px), calc(100% - 0px) calc(100% - 0px), calc(100% - 0px) calc(100% - 40px), 180px calc(100% - 40px), 140px 100%, 0 100%, 0 0);
  }
  header .clipHeader::before {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 0px) calc(100% - 40px), calc(100% - 0px) calc(100% - 0px), calc(100% - 0px) calc(100% - 40px), 180px calc(100% - 40px), 140px 100%, 0px 100%, 2px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 0px) calc(100% - 40px), calc(100% - 0px) calc(100% - 0px), calc(100% - 0px) calc(100% - 40px), 180px calc(100% - 40px), 140px 100%, 0px 100%, 2px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
  }
  .buttonGroup.btn1 {
    min-height: 70px;
    min-width: 140px;
  }
  .rightboxmenu {
    bottom: 0px;
  }
  header::before {
    height: 120px;
  }
  .logoDesktop {
    top: 68px;
  }
  .logoDesktop ul li {
    font-size: 20px;
  }
  .logoDesktop2 {
    top: 90px;
  }
  .logoDesktop2 ul li {
    font-size: 22px;
  }
}
@media only screen and (max-width: 992px) {
  .menu li {
    margin: 0px 9px;
  }
}
@media only screen and (max-width: 767px) {
  .logo img {
    max-width: 70px !important;
  }
}
@media only screen and (max-width: 390px) {
  .balancebox {
    font-size: 14px;
  }
  .balancebox li span {
    padding: 8px 10px;
  }
  .hamburger {
    width: 30px !important;
    height: 23px;
    margin-left: 10px;
  }
}