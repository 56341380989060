@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nova+Square&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mako&family=Nova+Square&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mako&family=Nova+Square&family=Squada+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aldrich&display=swap");
@font-face {
  font-family: "defiworld-Regular";
  src: url("../fonts/defiworld-Regular.eot");
  src: url("../fonts/defiworld-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/defiworld-Regular.woff2") format("woff2"), url("../fonts/defiworld-Regular.woff") format("woff"), url("../fonts/defiworld-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "defiworld-Bold";
  src: url("../fonts/defiworld-Bold.eot");
  src: url("../fonts/defiworld-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/defiworld-Bold.woff2") format("woff2"), url("../fonts/defiworld-Bold.woff") format("woff"), url("../fonts/defiworld-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "defiworld-Bold1";
  src: url("../fonts/defiworld-Bold1.eot");
  src: url("../fonts/defiworld-Bold1.eot?#iefix") format("embedded-opentype"), url("../fonts/defiworld-Bold1.woff2") format("woff2"), url("../fonts/defiworld-Bold1.woff") format("woff"), url("../fonts/defiworld-Bold1.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
:root {
  --loader-size: 100px;
}

body {
  margin: 0;
  font-family: "defiworld-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  color: #ffffff;
  text-align: left;
  background-image: url(../images/luxury-modern-background-with-geometric-abstract-shape_186931-87.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.h1,
h2,
h3 {
  font-family: "defiworld-bold";
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/futuristic.gif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -3;
  opacity: 0.8;
}

.box {
  display: flex;
  flex-direction: column;
}
.box.swapChange {
  flex-direction: column-reverse;
}

.banner-img {
  width: 700px;
  height: auto;
  margin: 0 auto;
}

.space-title {
  background-color: linear-gradient(84deg, rgb(158, 104, 221) 0%, rgb(229, 148, 229) 45%, rgb(107, 218, 176) 100%) text;
  font-family: "defiworld-Bold";
  text-shadow: 2px 2px 50px #50DBFF;
}

.os_container {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.os_container2 {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
}

.os_container3 {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-left: 30px;
}

.os_row {
  display: flex;
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

header .os_col {
  flex: 1;
  justify-content: space-between;
}
header .titles {
  font-size: 30px;
  text-align: center;
}
header .wallet {
  text-align: right;
}

.mainbox {
  /* Firefox */
}
.mainbox .title {
  text-align: center;
  margin: 0 0 15px;
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0;
  margin-bottom: 15px;
  color: hsl(199.38, 71.03%, 56.51%);
}
.mainbox input::-webkit-outer-spin-button,
.mainbox input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mainbox input[type=number] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}

#root {
  min-height: 100vh;
}

.mainbox {
  margin-top: 20px;
  margin-bottom: 30px;
}

.css-13cymwt-control {
  background-color: transparent !important;
  color: white !important;
}

.css-1nmdiq5-menu {
  background-color: black !important;
  color: white !important;
}

.coin-dropdown {
  background-color: transparent !important;
  color: white !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0px !important;
}

.css-1dimb5e-singleValue {
  color: white !important;
}

.css-13cymwt-control {
  background: transparent !important;
}

.css-t3ipsp-control {
  background: transparent !important;
  color: white;
}

.css-1nmdiq5-menu:hover {
  color: black !important;
}

.buttonGroup.btn2 {
  align-items: center;
  background-image: url(../../assets//images/btnbg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: inline-flex;
  font-size: 15px;
  font-weight: 800;
  margin: 0 auto;
  justify-content: center;
  letter-spacing: 0.8px;
  min-height: 91px;
  min-width: 180px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  background-position: center;
  text-decoration: none;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.buttonGroup.btn2:hover {
  color: #00FFFF !important;
}

.borderboxmain img.linetopleft {
  top: 1vw;
  left: 1vw;
  -o-object-position: left;
     object-position: left;
  width: 550px;
  transform: scaleY(-1);
}

.borderboxmain .img {
  width: calc(100% - 2vw);
  height: auto;
  display: block;
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
}

.borderboxmain img.linebottomright {
  width: 550px;
  bottom: 1vw;
  right: 1vw;
  -o-object-position: right;
     object-position: right;
}

.borderboxmain .img {
  width: calc(100% - 2vw);
  height: auto;
  display: block;
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
}

.a {
  color: #999;
  font: normal 1.1em/3.11em Electrolize, "Cultive Mono", Tahoma, sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  height: 60px;
  transition: all 330ms linear;
}
.a span[class^=cta] {
  width: 100%;
  display: block;
  padding: 0 29px;
  text-shadow: 0 0 30px #999;
}
.a span[class^=cta]:before {
  content: attr(data-content);
}
.a .skew {
  transform: skew(-45deg);
  border: 1px solid transparent;
  border-right-width: 2px;
  padding: 24px;
  display: block;
  transition: all 330ms linear;
}

.a.holo-btn-fed {
  color: #00f7ff;
}
.a.holo-btn-fed:hover {
  color: #e5Feff;
}
.a.holo-btn-fed .skew {
  -o-border-image: linear-gradient(top left, #99fcff 0%, #00f7ff 100%) 1;
     border-image: linear-gradient(top left, #99fcff 0%, #00f7ff 100%) 1;
}

.boxmain {
  border: 1px solid hsl(199.38, 71.03%, 56.51%);
  border-radius: 41px;
  position: relative;
  text-align: center;
  background-color: black !important;
}
.boxmain .swapbtn {
  margin: 4px auto;
  text-align: center;
  position: relative;
  z-index: 2;
  background-color: #ff84d9;
  width: 12px;
  height: 12px;
  margin-left: auto;
  margin-right: auto;
  transform: scale(4);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.boxmain .swapbtn img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 6px;
  filter: invert(1);
}

.approvebtn {
  padding: 0;
  width: 100%;
  height: 60px;
  border: 1px solid hsl(199.38, 71.03%, 56.51%);
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  margin-top: 25px;
  color: #ffffff;
  font-size: 22px;
}
.approvebtn.mt-25 {
  margin-top: 25px;
}
.approvebtn img {
  height: 14px;
  width: auto;
  position: relative;
  left: 8px;
  filter: invert(1);
}
.approvebtn span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px 0px;
}
.approvebtn span::before, .approvebtn span::after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: #ffffff;
  transition: all 0.3s ease;
}
.approvebtn span::before {
  width: 2px;
  height: 0%;
}
.approvebtn span::after {
  width: 0%;
  height: 2px;
}
.approvebtn span:hover::before {
  height: 100%;
}
.approvebtn span:hover::after {
  width: 100%;
}
.approvebtn::before, .approvebtn::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: #ffffff;
  transition: all 0.3s ease;
}
.approvebtn::before {
  height: 0%;
  width: 2px;
}
.approvebtn::after {
  width: 0%;
  height: 2px;
}
.approvebtn:hover {
  background: transparent;
  color: #ffffff;
}
.approvebtn:hover::before {
  height: 100%;
}
.approvebtn:hover::after {
  width: 100%;
}

.boxinner {
  background-color: rgba(74, 129, 161, 0.18);
  padding: 10px 30px 30px;
  border-radius: 7px;
  position: relative;
  text-align: left;
  border: 1px solid #426a9b;
}
.boxinner::before {
  content: "";
  position: absolute;
  bottom: -14px;
  left: -9px;
  background-color: #020204;
  transform: rotate(-45deg);
  width: 25px;
  height: 35px;
  border-right: 1px solid #426a9b;
}
.boxinner::after {
  content: "";
  position: absolute;
  top: -14px;
  right: -9px;
  background-color: #020204;
  transform: rotate(-45deg);
  width: 25px;
  height: 35px;
  border-left: 1px solid #426a9b;
}
.boxinner p {
  margin: 0px;
  text-transform: capitalize;
  color: #a5a5a5;
  font-weight: 400;
  font-size: 16px;
}
.boxinner strong {
  margin: 0px;
  text-transform: capitalize;
  color: #a5a5a5;
  font-weight: 400;
  font-size: 16px;
}
.boxinner strong span {
  color: #ffffff;
}
.boxinner .boxpart {
  margin-top: 20px;
}
.boxinner .leftbx {
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.boxinner .leftbx img {
  display: block;
  width: 40px;
  margin-right: 10px;
  background: transparent;
  border-radius: 50px;
  padding: 3px;
  height: 30px;
  -o-object-fit: contain;
     object-fit: contain;
}
.boxinner .leftbx strong {
  font-weight: 500;
  color: #ffffff;
}
.boxinner .leftbx .btnaddtkn {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 7px;
  color: #ffe6bf;
  font-size: 14px;
  text-decoration: none;
}
.boxinner .leftbx .btnaddtkn:hover {
  text-decoration: underline;
}
.boxinner .leftbx p {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 12px;
  font-size: 14px;
}
.boxinner .leftbx p span {
  color: #ffffff;
}
.boxinner input {
  flex: 1;
  margin-left: 20px;
  border: 1px solid #444444;
  padding: 10px 12px;
  border-radius: 5px;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  color: #ffffff;
  text-align: right;
  width: 100%;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.overlaypopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.83);
  z-index: 3;
}

.ospopup {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: auto;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(245, 246, 252);
  box-shadow: rgba(51, 53, 72, 0.04) 8px 12px 20px, rgba(51, 53, 72, 0.02) 4px 6px 12px, rgba(51, 53, 72, 0.04) 4px 4px 8px;
  padding: 0px;
  width: 90%;
  overflow: hidden auto;
  max-width: 420px;
  max-height: 90vh;
  border-radius: 20px;
  color: #222222;
  transform: translate(-50%, -50%);
  z-index: 4;
}
.ospopup .popuptitle {
  padding: 15px 15px 20px;
}
.ospopup .popuptitle h3 {
  margin: 0px;
  font-size: 16px;
}
.ospopup .popuptitle button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.ospopup .popuptitle button svg {
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-50%, -50%);
}
.ospopup .popuptitle button::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
}

.popupbtngrp {
  padding: 0px 7px 15px;
}
.popupbtngrp button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px;
  background-color: #e8ecfb;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  color: #0d111c;
  font-size: 16px;
  font-weight: 600;
  flex: 0 0 calc(50% - 14px);
  max-width: calc(50% - 14px);
  margin-left: 7px;
  margin-right: 7px;
  font-family: "Montserrat", sans-serif;
}
.popupbtngrp button img {
  height: 28px;
  width: 28px;
  margin-right: 12px;
}

.osprivacy {
  padding: 10px 15px 15px;
  margin-bottom: 10px;
  color: #7780a0;
}
.osprivacy a {
  text-decoration: none;
  cursor: pointer;
  color: #fb118e;
  font-weight: 500;
}

.boxinner.bxinner.space-y-4 {
  margin-top: 20px;
}

button.approvebtn.bxinner.flex.justify-between.items-center.mt-6.bg-blue-500.text-white.p-2.rounded {
  margin-top: 20px;
}

.confirmingtransaction {
  text-align: center;
}
.confirmingtransaction .loaderimg {
  width: 100%;
  max-width: 60px;
}
.confirmingtransaction h2 {
  margin: 20px 0 0;
  font-size: 26px;
  font-weight: 500;
}
.confirmingtransaction .approvebtn {
  max-width: calc(100% - 40px);
  margin: 30px auto 20px;
  border: none;
}

.anltycs {
  text-shadow: 2px 2px 50px #50DBFF;
}

.boxsmain {
  margin-top: 50px;
}
.boxsmain .os_container {
  max-width: 800px;
}
.boxsmain .os_row {
  justify-content: center;
}
.boxsmain .col4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 15px;
}
.boxsmain .cardbox {
  background: linear-gradient(rgb(13, 50, 82) 0%, rgba(17, 48, 73, 0) 70.45%);
  padding: 20px 15px;
  border-radius: 7px;
  position: relative;
  text-align: center;
  border: 1px solid hsl(199.38, 71.03%, 56.51%);
  height: 100%;
}
.boxsmain .cardbox h2 {
  margin: 0 0 8px;
  font-weight: 500;
}
.boxsmain .cardbox p {
  margin: 0px;
  font-size: 16px;
}
.boxsmain .cardbox::after {
  content: "";
  position: absolute;
  top: -14px;
  right: -9px;
  background-color: #000000;
  transform: rotate(-45deg);
  width: 25px;
  height: 35px;
  border-left: 1px solid hsl(199.38, 71.03%, 56.51%);
}
.boxsmain .cardbox::before {
  content: "";
  position: absolute;
  bottom: -14px;
  left: -9px;
  background-color: #000000;
  transform: rotate(-45deg);
  width: 25px;
  height: 35px;
  border-right: 1px solid hsl(199.38, 71.03%, 56.51%);
}
.boxsmain .titleh2 {
  text-align: center;
}
.boxsmain .titleh2 h2 {
  text-align: center;
  margin: 0 0 15px;
  font-size: 42px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0;
  margin-bottom: 15px;
  color: #ffffff;
}

.content--canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: hue-rotate(1deg);
  opacity: 0.2;
}

.wrapper {
  min-height: 100vh;
}

.adminReportTable .os_container {
  max-width: 800px;
}
.adminReportTable .react-tabs .react-tabs__tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.adminReportTable .react-tabs .react-tabs__tab-list .react-tabs__tab {
  width: 100%;
  padding: 15px;
  text-align: center;
}
.adminReportTable .table-responsive {
  overflow: auto;
}
.adminReportTable .table-responsive .text-right {
  text-align: right;
}
.adminReportTable .table-responsive .thead .tr {
  background-color: #680d48;
}
.adminReportTable .table-responsive .thead .tr .th {
  padding: 15px;
}
.adminReportTable .table-responsive .tbody .tr .td {
  border-bottom: 1px solid #323232;
  padding: 15px 0px;
  margin: 0;
  width: 100%;
}
.adminReportTable .table-responsive .tr .td {
  padding: 10px;
}
.adminReportTable .table-responsive .table {
  width: 100%;
}

/* Customize react-select styles if needed */
.select-dropdown {
  background-color: transparent;
}

.coin-dropdown {
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: black;
}
.coin-dropdown option {
  color: black;
}

.coin-option {
  padding-left: 30px;
  /* Make room for the image */
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 5px center;
  min-height: 60px;
  /* Adjust if needed */
}

.cardbox2 {
  border-radius: 8px;
  border: 1px solid hsl(199.38, 71.03%, 56.51%);
}
.cardbox2 h2 {
  margin: 0 0 8px;
  font-size: 32px;
  font-weight: 600;
}
.cardbox2 p {
  margin: 0px;
  font-size: 16px;
}

.os_row2 {
  display: flex;
  flex-wrap: wrap;
}

.os_row3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.boxsmain2 {
  margin-top: 50px;
}
.boxsmain2 .os_container {
  max-width: 1320px;
}
.boxsmain2 .os_row {
  justify-content: center;
}
.boxsmain2 .col4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 20px;
}

.cardbox2 {
  padding: 0px 0px;
  border-radius: 15px;
  position: relative;
  text-align: center;
  height: 100%;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  /* background: transparent; */
}
.cardbox2 .vtn-card-img {
  opacity: 0.5;
  filter: hue-rotate(298deg);
}
.cardbox2 .iconbox {
  max-width: 100px;
  margin: 15px auto;
  position: relative;
  border-radius: 50%;
  padding: 0;
}
.cardbox2 .iconbox img {
  width: 100%;
  height: auto;
  animation: move 2s linear infinite;
}
.cardbox2 h2 {
  margin: 0 0 0px;
  font-size: 20px;
  font-weight: 600;
}
.cardbox2 p {
  margin: 0px;
  font-size: 16px;
}

@keyframes move {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.table-responsive {
  border: 1px solid hsl(199.38, 71.03%, 56.51%) !important;
}
.table-responsive .text-right {
  text-align: right;
}
.table-responsive thead {
  background-color: transparent;
  background: linear-gradient(rgb(13, 50, 82) 0%, rgb(59, 143, 212) 70.45%);
  --cui-table-color-state: hsl(199.38deg 71.03% 56.51%);
  --cui-border-width: 0px;
}
.table-responsive thead tr {
  background-color: transparent;
  background: linear-gradient(rgb(4, 25, 43) 0%, rgb(4, 7, 19) 70.45%);
}
.table-responsive thead th {
  padding: 15px;
}

tbody tr td {
  border-bottom: 1px solid #323232;
  padding: 15px 0px;
  margin: 0;
}

tr td {
  padding: 10px;
  text-align: center;
}

table {
  width: 100%;
}

.header-cell {
  border-right: 1px solid #fff;
  /* Add a right border to the header cells */
}

.adminReportTable {
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: auto;
}

.adminReportTable2 {
  margin-bottom: 40px;
  overflow: hidden;
}

.boxsmaint {
  margin-top: 50px;
}
.boxsmaint .os_container {
  max-width: 1320px;
}

.css-vubbuv {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.dashboardTable th,
.dashboardTable td {
  border: 1px solid hsl(199.38, 71.03%, 56.51%);
  /* Add borders to table cells */
  padding: 8px;
  /* Add padding to improve cell spacing */
  text-align: left;
  /* Adjust text alignment if needed */
}

.historysec {
  padding-block: 50px;
}
.historysec nav {
  justify-content: space-around;
  align-items: center;
  padding-block: 15px;
}
.historysec table thead tr {
  background-color: transparent;
  background-image: linear-gradient(rgb(4, 25, 43) 0%, rgb(4, 7, 19) 70.45%);
  border: 1px solid #fff;
  /* Add borders to table cells */
  padding: 8px;
  /* Add padding to improve cell spacing */
  text-align: left;
  /* Adjust text alignment if needed */
  justify-content: space-between;
}
.historysec table {
  position: relative;
  border: 1px solid #791685;
  table-layout: fixed;
}
.historysec table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.historysec .page-link {
  background-color: transparent;
  color: #fff;
}

.titlebox {
  margin-top: 10px !important;
  text-align: center;
  max-width: 500px;
  margin: 0px auto 80px;
  position: relative;
}
.titlebox::before, .titlebox::after {
  content: "";
  position: absolute;
  top: 60%;
  width: 100%;
  height: 70px;
  background-image: url(../../assets//images/titleframe.svg);
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 9;
  filter: hue-rotate(40deg);
  animation: blinkers 1.5s linear infinite;
  filter: drop-shadow(0 0 2px #50DBFF) hue-rotate(40deg);
  transition: 1s;
}
@keyframes blinkers {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
.titlebox::before {
  left: 100%;
  transform: translate(5%, -50%);
}
.titlebox::after {
  right: 100%;
  transform: translate(-5%, -50%) scaleX(-1);
}
.titlebox.titleboxNft {
  max-width: 600px;
  margin: 0px auto 10px;
}
.titlebox.titleboxNft h2 {
  margin-bottom: 10px;
}
.titlebox.titleboxNft::before, .titlebox.titleboxNft::after {
  display: none;
}

.history-headline {
  font-family: "Zen Dots", Sans-serif;
  font-size: 45px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 40px;
}
.history-headline span {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.00000001);
}

.carousel .control-dots {
  position: absolute;
  top: 30px;
  /* Adjust this value as necessary */
  right: 50%;
  transform: translateX(50%);
  /* Centering it horizontally */
  bottom: auto;
  flex-direction: row;
  justify-content: center;
  /* If you want the buttons centered */
}

/* Base Styles */
.carousel-indicator-button {
  width: 100px;
  height: 40px;
  margin-right: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  /* Full-rounded button */
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  outline: none;
  cursor: pointer;
}

/* Hover State */
/* Focus State */
.carousel-indicator-button:focus {
  box-shadow: 0 0 0 4px #3f26d9;
  /* ring and ring-blue-500 */
}

/* Active State */
.carousel-indicator-active {
  background-color: transparent;
  background-image: linear-gradient(rgb(4, 25, 43) 0%, rgb(4, 7, 19) 70.45%);
  /* bg-blue-500 */
  color: #F9FAFB;
  /* text-white */
}

/* Inactive State */
.carousel-indicator-inactive {
  background-color: transparent;
  /* bg-gray-200 */
  color: #fff;
  /* text-gray-900 */
}

/*DATE PICKER */
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.MuiStack-root.MuiMultiInputDateRangeField-root.css-hw8s2-MuiStack-root-MuiMultiInputDateRangeField-root {
  width: 500px !important;
}

body .MuiFormControl-root-MuiTextField-root {
  border: 1px solid !important;
}

body .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  width: 500px !important;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed to center vertically */
}

.loader {
  width: var(--loader-size);
  height: var(--loader-size);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #ade8f4 #ade8f4 transparent #ade8f4;
  box-sizing: border-box;
  animation: rotation 1.5s linear infinite;
}

.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent hsl(199.38, 71.03%, 56.51%) hsl(199.38, 71.03%, 56.51%);
  width: calc(var(--loader-size) - 15px);
  height: calc(var(--loader-size) - 15px);
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.7s linear infinite;
  transform-origin: center center;
}

.loader::before {
  width: calc(var(--loader-size) - 30px);
  height: calc(var(--loader-size) - 30px);
  border-color: #FFF #FFF transparent transparent;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@media only screen and (max-width: 768px) {
  .boxsmain .col4 {
    padding: 8px;
  }
  .boxsmain .cardbox {
    padding: 15px 8px;
  }
  .boxsmain .cardbox p {
    font-size: 16px;
    min-height: 35px;
    line-height: 1.1;
  }
}
@media only screen and (max-width: 768px) {
  .boxsmain2 .col4 {
    padding: 8px;
  }
  .boxsmain2 .cardbox2 {
    padding: 0px 1px;
  }
  .boxsmain2 .cardbox2 p {
    font-size: 16px;
    min-height: 35px;
    line-height: 1.1;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
}
@media only screen and (max-width: 575px) {
  .hamburger {
    width: 25px !important;
  }
  .os_container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .os_container2 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .boxsmain2 .col4 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
    margin-bottom: 20px;
  }
  .boxinner {
    padding: 25px 15px 30px;
  }
  .popupbtngrp {
    padding: 0px 15px;
  }
  .popupbtngrp button {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  header .titles {
    font-size: 26px;
  }
  .boxsmain .col4 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
    margin-bottom: 20px;
  }
  .adminform .boxinner .inputbox button {
    margin-left: 15px;
    min-width: 85px;
    font-size: 14px;
    padding: 10px;
    text-transform: capitalize;
  }
  .adminform .boxinner input {
    flex-basis: calc(100% - 100px);
    max-width: calc(100% - 100px);
  }
}
@media only screen and (max-width: 767px) {
  .titlebox::before, .titlebox::after {
    display: none;
  }
}
@media (max-width: 365px) {
  .nav-btn-area .btn-main {
    padding: 6px 3px;
  }
  .nav-brand .img {
    width: 50px;
    height: auto;
  }
}
@media (max-width: 300px) {
  .nav-btn-area .btn-main {
    padding: 2px 1px;
  }
}
@media (max-width: 575px) {
  .buttonGroup.btn2 {
    min-height: 71px;
    min-width: 160px;
  }
}
@media (max-width: 400px) {
  .buttonGroup.btn2 {
    min-height: 71px;
    min-width: 140px;
  }
}
@media (max-width: 340px) {
  .buttonGroup.btn2 {
    font-size: 12px;
    min-height: 81px;
    min-width: 129px;
  }
}
@media (min-width: 1400px) {
  .container2 {
    max-width: 1320px;
  }
}
@media (min-width: 1200px) {
  .container2 {
    max-width: 1140px;
  }
}
@media (min-width: 992px) {
  .container2 {
    max-width: 960px;
  }
}
@media (min-width: 768) {
  .container2 {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container2 {
    max-width: 540px;
  }
}/*# sourceMappingURL=style.css.map */